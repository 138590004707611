import React from 'react';
import './Header.css';

const Header = () => {
    return (
        <header className="header-section">
            <nav className="header-nav">
                <li><a href="#about">About Me</a></li>
                <li><a href="#skills">Skills</a></li>
                <li><a href="#projects">Projects</a></li>
                <li><a href="#experiences">Experiences</a></li>
                <li><a href="#contact">Contact</a></li>
            </nav>
        </header>
    );
};

export default Header;
