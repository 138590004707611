import React from 'react';
import './Projects.css';
import nexusAI from '../nexusai.png';
import mirAI from '../mirai.png'
import playlistPal from '../playlistpal.png';

const Projects = () => {
    return (
        <section className="projects-section" id="projects">
            <h2>Projects I've Worked On</h2>
            <div className="project-card">

                <a href="https://github.com/YJSong30/NexusAI" target="blank" id="project-link">
                    <div className="card" id="nexusai">
                        <div className="project-image">
                            <img src={nexusAI} alt="nexusAI" />
                        </div>
                        <div class="project-content">
                            <h3>NexusAI</h3>
                            <p>A full-stack web application that allows users to create their own 2-D backgrounds through keywords using Generative AI.</p>
                            <p>Technologies: <b>Python, JavaScript, ReactJS, HTML/CSS, Docker, Amazon S3, AWS Sagemaker, Firebase, Figma</b></p>
                        </div>
                    </div>
                </a>

                <a href="https://github.com/vydpnguyen/mri-reflex-app" target="blank" id="project-link">
                    <div className="card" id="mirai">
                        <div className="project-image">
                            <img src={mirAI} alt="mirAI" />
                        </div>
                        <div class="project-content">
                            <h3>mirAI</h3>
                            <p>A full-stack web application that classifies brain tumors using a model and fine-tuned using Intel Developer Cloud.</p>
                            <p>Technologies: <b>Python, Reflex, Intel Developer Cloud, Figma</b></p>
                        </div>
                    </div>
                </a>

                <a href="https://github.com/vydpnguyen/playlistpal-spotify-playlist-generator" target="blank" id="project-link">
                    <div className="card" id="playlistpal">
                        <div className="project-image">
                            <img src={playlistPal} alt="playlistPal" />
                        </div>
                        <div class="project-content">
                            <h3>PlaylistPal</h3>
                            <p>An interactive React application that allows users to search for Spotify library, create a custom playlist, then save it to their account.</p>
                            <p>Technologies: <b>JavaScript, HTML/CSS, React, Figma</b></p>
                        </div>
                    </div>
                </a>

            </div>
        </section>
    );
};

export default Projects;
