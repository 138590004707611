import React from 'react';
import './Skills.css';

const Skills = () => {
    return (
        <section className="skills-section" id="skills">
            <h2>My Skills</h2>
            <div id="frontend">
                <h3>Frontend</h3>
                <div className="skill-card">
                    <div className="skill-item">
                        <img src="https://upload.wikimedia.org/wikipedia/commons/6/6a/JavaScript-logo.png" alt="JavaScript Logo" />
                        <p>JavaScript</p>
                    </div>
                    <div className="skill-item">
                        <img src="https://upload.wikimedia.org/wikipedia/commons/thumb/6/61/HTML5_logo_and_wordmark.svg/1200px-HTML5_logo_and_wordmark.svg.png" alt="HTML Logo" />
                        <p>HTML</p>
                    </div>
                    <div className="skill-item">
                        <img src="https://upload.wikimedia.org/wikipedia/commons/d/d5/CSS3_logo_and_wordmark.svg" alt="CSS Logo" />
                        <p>CSS</p>
                    </div>
                    <div className="skill-item">
                        <img src="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSlGmKtrnxElpqw3AExKXPWWBulcwjlvDJa1Q&s" alt="ReactJS Logo" />
                        <p>ReactJS</p>
                    </div>
                    <div className="skill-item">
                        <img src="https://raw.githubusercontent.com/reduxjs/redux/master/logo/logo.png" alt="ReduxJS Logo" />
                        <p>ReduxJS</p>
                    </div>
                    <div className="skill-item">
                        <img src="https://upload.wikimedia.org/wikipedia/commons/thumb/3/33/Figma-logo.svg/1667px-Figma-logo.svg.png" alt="Figma Logo" />
                        <p>Figma</p>
                    </div>
                </div>
            </div>

            <div id="backend">
                <h3>Backend</h3>
                <div className="skill-card">
                    <div className="skill-item">
                        <img src="https://cdn.iconscout.com/icon/free/png-256/free-python-logo-icon-download-in-svg-png-gif-file-formats--technology-social-media-vol-5-pack-logos-icons-3030224.png?f=webp&w=256" alt="Python Logo" />
                        <p>Python</p>
                    </div>
                    <div className="skill-item">
                        <img src="https://cdn4.iconfinder.com/data/icons/logos-and-brands/512/181_Java_logo_logos-512.png" alt="Java Logo" />
                        <p>Java</p>
                    </div>
                    <div className="skill-item">
                        <img src="https://upload.wikimedia.org/wikipedia/commons/1/19/C_Logo.png" alt="C Logo" />
                        <p>C</p>
                    </div>
                    <div className="skill-item">
                        <img src="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSBwzWqFVu66ck-2u_nDBgLTZbR3cNjpUCbWg&s" alt="NodeJS Logo" />
                        <p>NodeJS</p>
                    </div>
                    <div className="skill-item">
                        <img src="https://uxwing.com/wp-content/themes/uxwing/download/brands-and-social-media/google-tensorflow-icon.png" alt="TensorFlow Logo" />
                        <p>TensorFlow</p>
                    </div>
                    <div className="skill-item">
                        <img src="https://miro.medium.com/v2/resize:fit:1200/1*J3G3akaMpUOLegw0p0qthA.png" alt="REST APIs Logo" />
                        <p>REST APIs</p>
                    </div>
                </div>
            </div>

            <div id="database">
                <h3>Databases & Cloud</h3>
                <div className="skill-card">
                    <div className="skill-item">
                        <img src="https://static-00.iconduck.com/assets.00/sql-database-sql-azure-icon-1955x2048-4pmty46t.png" alt="SQL Logo" />
                        <p>SQL</p>
                    </div>
                    <div className="skill-item">
                        <img src="https://upload.wikimedia.org/wikipedia/commons/2/29/Postgresql_elephant.svg" alt="PostgreSQL Logo" />
                        <p>PostgreSQL</p>
                    </div>
                    <div className="skill-item">
                        <img src="https://seeklogo.com/images/F/firebase-logo-402F407EE0-seeklogo.com.png" alt="Firebase Logo" />
                        <p>Firebase</p>
                    </div>
                    <div className="skill-item">
                        <img src="https://cdn-blog.lawrencemcdaniel.com/wp-content/uploads/2021/01/30083957/aws-s3-logo.png" alt="Amazon S3" />
                        <p>Amazon S3</p>
                    </div>
                    <div className="skill-item">
                        <img src="https://d1.awsstatic.com/product-marketing/IronMan/AWS-service-icon_sagemaker.5ccec16f16a04ed56cb1d7f02dcdada8de261923.png" alt="Amazon S3" />
                        <p>Amazon Sagemaker</p>
                    </div>
                </div>
            </div>

            <div id="tools">
                <h3>DevOps & Tools</h3>
                <div className="skill-card">
                    <div className="skill-item">
                        <img src="https://static-00.iconduck.com/assets.00/git-icon-2048x2048-juzdf1l5.png" alt="Git Logo" />
                        <p>Git</p>
                    </div>
                    <div className="skill-item">
                        <img src="https://cdn-icons-png.flaticon.com/512/25/25231.png" alt="GitHub Logo" />
                        <p>GitHub</p>
                    </div>
                    <div className="skill-item">
                        <img src="https://static-00.iconduck.com/assets.00/docker-icon-2048x2048-5mc7mvtn.png" alt="Docker Logo" />
                        <p>Docker</p>
                    </div>
                </div>
            </div>

        </section>

    );
};

export default Skills;
