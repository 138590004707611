import React from 'react';
/* import { useState } from 'react'; */

import './Contact.css';

const Contact = () => {
    /*
    const [name, setName] = useState('');
    const [email, setEmail] = useState('');
    const [message, setMessage] = useState('');

    const handleSubmit = async (event) => {
        event.preventDefault();
        // Example API call to submit the contact form
        const response = await fetch('/api/contact', {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({ name, email, message }),
        });

        const result = await response.text();
        console.log(result); // Handle the response (e.g., display a success message)
    };
    */
    return (
        <section className="contact-section" id="contact">
            <h1>Let's Get In Touch!</h1>
            <div className="contact-info">
                <h3 className="email">
                    <b>Email</b>:
                    <a href="mailto:vydpnguyen@gmail.com" id="email-link"> <u>vydpnguyen@gmail.com</u></a>
                </h3>
                <h3 className="social-links" id="bold">
                    <a href="https://www.linkedin.com/in/vydpnguyen/" target="_blank" rel="noopener noreferrer">LinkedIn</a>
                    &nbsp;|&nbsp;
                    <a href="https://github.com/vydpnguyen" target="_blank" rel="noopener noreferrer">GitHub</a>
                    &nbsp;|&nbsp; 
                    <a href="https://www.instagram.com/my_melovy/" target="_blank" rel="noopener noreferrer">Instagram</a>
                </h3>
            </div>

            {/*
            <form onSubmit={handleSubmit}>
                <input
                    type="text"
                    placeholder="Name"
                    value={name}
                    onChange={(e) => setName(e.target.value)}
                />
                <input
                    type="email"
                    placeholder="Email"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                />
                <textarea
                    placeholder="Message"
                    value={message}
                    onChange={(e) => setMessage(e.target.value)}
                />
                <button type="submit">Send</button>
            </form>
            */}
        </section>

    );
};

export default Contact;
