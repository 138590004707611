import React from 'react';
import './Experiences.css';

const Experiences = () => {
    return (
        <section className="experiences-section" id="experiences">
            <h2>My Experiences</h2>
            <div className="experience-card">

                <div id="research" className="experience-item">
                    <div id="researcher" className="experience-name">
                        <h3><span className="pink">Graduate Student Researcher</span></h3>
                        <h3 id="experience-date">University of the Pacific | Fall 2024 - Present</h3>
                    </div>
                    <div className="experience-details">
                        <ul>
                            <li>
                                Collaborated with faculty on researching and training a large language model using <b>Python</b> and <b>TensorFlow</b> to generate test cases from RFC inputs for web apps
                            </li>
                            <li>
                                Responsible for literature review, discussion for feedback, and report production by contributing 5 hours per week.
                            </li>
                        </ul>
                    </div>
                </div>

                <div id="ga" className="experience-item">
                    <div className="experience-name">
                        <h3><span className="pink">Graduate Assistant</span></h3>
                        <h3 id="experience-date">University of the Pacific | Fall 2024 - Present</h3>
                    </div>
                    <div className="experience-details">
                        <ul>
                            <li>
                                Dedicated 20 hours per week to academic support for undergraduates in Computer Simulation, Algorithms & Advanced Algorithms courses
                            </li>
                            <li>
                                Assisted professors with grading, hosted online and in-person office hours, and additional sessions in Student Support Center
                            </li>
                            <li>
                                Provided additional assistance to students on homework, labs and reference to course materials
                            </li>
                        </ul>
                    </div>
                </div>

                <div id="gdsc" className="experience-item">
                    <div className="experience-name">
                        <h3><span className="pink">Google Developer Student Clubs Lead</span></h3>
                        <h3 id="experience-date">University of the Pacific | Summer 2023 - Present</h3>
                    </div>
                    <div className="experience-details">
                        <ul>
                            <li>
                                Presided slide deck for weekly club meetings, host meetings with officers to plan upcoming events
                            </li>
                            <li>
                                Conducted hands-on bi-weekly workshops for 25+ students on website development, instructing Javascript, ReactJS, HTML/CSS
                            </li>
                            <li>
                                Led recruitment efforts by coordinating events, handling financial, administrative, and managerial responsibilities
                            </li>
                            <li>
                                Managed a Discord server with 200+ members by sending out announcements and interacting with members
                            </li>
                        </ul>
                    </div>
                </div>





            </div>
        </section>
    );
};

export default Experiences;
