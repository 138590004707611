import React from 'react';
import './Main.css';
import profilePic from '../vy_nguyen_photo.jpeg';

const Main = () => {
    return (
        <section className="main-section" id="main">
            <div className="name-container">
                <h1>Hello, I'm Vy Nguyen!</h1>
                <h2>Computer Science Graduate Assistant | Machine Learning & Software Testing Student Researcher</h2>
                <div className="button-container">
                    <a href="#contact">
                        <button id="about">Contact Me</button>
                    </a>
                    {/*<button id="contact">Contact Me</button>*/}
                </div>
            </div>
            <div className="image-container">
                <img src={profilePic} alt="Profile" />
            </div>
        </section>
    );
};

export default Main;
