import React from 'react';
import './About.css';
import gradPhoto from '../grad_picture.jpg'

const About = () => {
    return (
        <section className="about-section" id="about">
            <h2>About Me</h2>
            <div className="about-container">
            <div className="text-container">
                <p>
                    I’m currently pursuing a <b>Master's in Computer Science</b> at <b>University of the Pacific</b> in Stockton, California. I also graduated here with a Bachelor's degree in the same major in May, 2024.
                </p>
                <p>
                    I am passionate about <b>full-stack web development</b>, especially front-end as I love to design. With a strong experience in <b>Java, JavaScript, Python, and React,</b> I’m constantly expanding my skills through projects outside of class.
                </p>
                <p>
                    I am actively participating in a research in <b>machine learning</b> with a faculty in my Computer Science department, utilizing <b>TensorFlow</b> for natural language processing.
                </p>
                <p>
                    Outside of studying, I love to bake, travel and try out good food! :)
                </p>
            </div>
            <div className="image-container">
                <img src={gradPhoto} alt="Profile" />
            </div>
            </div>
        </section>
    );
};

export default About;
